import React from 'react'
import {Global} from '@emotion/core'
import {GlobalStyle} from './App.style'
import {RecoilRoot} from 'recoil'

import LoadingSpinner from './components/loading-spinner'
import {hasLoggedInUser} from './utils/auth-client'

import './tailwind.css'

const loadAuthenticatedApp = () => import('./authenticated-app')
const AuthenticatedApp = React.lazy(
  /* webpackPrefetch: true */ loadAuthenticatedApp,
)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
  const user = hasLoggedInUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  return (
    <RecoilRoot>
      <Global styles={GlobalStyle} />

      <React.Suspense
        fallback={
          <div>
            <LoadingSpinner />
          </div>
        }
      >
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </RecoilRoot>
  )
}

export default App

import decode from 'jwt-decode'
import {logout, TOKEN_LOCAL_STORAGE_KEY} from './global'
import {logoutCurrentUser} from './auth-client'
import {notification} from 'antd'

interface ClientArgs {
  method?: any
  body?: any
  customConfig?: any
}

const EXPIRE_IN_MIN = 50
const API_CALL_TIMEOUT = 60000

interface JsonJwt {
  jwt: string
}

const refreshToken = () => {
  return new Promise<string>(async (resolve, reject) => {
    const token = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)

    const controller = new AbortController()

    const id = setTimeout(() => controller.abort(), API_CALL_TIMEOUT)

    const refreshTokenConfig: any = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'arc-app-id': process.env.REACT_APP_FOLDER,
        Authorization: `Bearer ${token}`,
      },
    }

    window.onbeforeunload = function () {
      controller.abort()
    }

    await window
      .fetch(`${process.env.REACT_APP_AUTH_URL}/refresh`, {
        ...refreshTokenConfig,
        signal: controller.signal,
      })
      .then((r: any) => {
        if (r.status === 401) {
          return logout()
        }
        if (r.status === 404) {
          throw new Error('Could not connect to server (404)')
        }
        clearTimeout(id)
        return r.json().then((json: JsonJwt) => {
          window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, json.jwt)
          resolve(json.jwt)
        })
      })
      .catch(e => {
        clearTimeout(id)
        console.error('APIClient1:Error', e)

        reject(e)
      })
  })
}

const client = (
  endpoint: string,
  args: ClientArgs = {customConfig: {}},
  isTokenRequired = true,
) => {
  return new Promise(async (resolve, reject) => {
    const controller = new AbortController()

    const id = setTimeout(() => controller.abort(), API_CALL_TIMEOUT)

    const {body, method, customConfig = {}} = args
    let token: string | null = window.localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY,
    )
    const headers: any = {
      'content-type': 'application/json',
      'arc-app-id': process.env.REACT_APP_FOLDER,
    }

    if (!isTokenRequired) {
      headers['x-arc-no-token-required'] = 'true'
    }

    if (isTokenRequired && token) {
      headers.Authorization = `Bearer ${token}`

      try {
        const decoded: any = decode(token)
        const expireIn = new Date().getTime() - new Date(decoded.exp).getTime()
        const expireInMinutes = Math.floor(expireIn / 1000 / 60) % 60

        if (expireInMinutes >= EXPIRE_IN_MIN) {
          const tempToken = await refreshToken()
          if (tempToken) {
            token = tempToken
          }
        }
      } catch (error) {
        logoutCurrentUser()

        window.location.href = '/'
      }
    } else {
      if (isTokenRequired) {
        throw new Error(`The session has expired, please login again.`)
      }
    }

    headers.Authorization = `Bearer ${token}`

    const config: any = {
      method: method || (body ? 'POST' : 'GET'),
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }

    if (body) {
      config.body = JSON.stringify(body)
    }

    if (process.env.NODE_ENV === 'development') {
      console.debug('API:URL')
    }

    window.onbeforeunload = function () {
      controller.abort()
    }

    return window
      .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        ...config,
        signal: controller.signal,
      })
      .then((r: any) => {
        if (r.status === 401) {
          return logout()
        }
        if (r.status === 404) {
          throw new Error('Could not connect to server (404)')
        }
        return r.json().then((json: any) => {
          if (json.code === 400) {
            notification.error({
              message: json.message,
              placement: 'bottomRight',
            })
            reject(json.message)
          }

          clearTimeout(id)

          resolve(json)
        })
      })
      .catch(e => {
        console.error('APIClient2:Error', e)
        notification.error({
          message: e.message,
          placement: 'bottomRight',
        })

        clearTimeout(id)

        reject(e)
      })
  })
}

export default client

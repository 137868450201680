/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './loading-spinner.style'

import {Spin} from 'antd'

function LoadingSpinner() {
  return (
    <div css={style.LoadingSpinnerStyle}>
      <Spin size="large" css={{fontSize: '2em'}} />
    </div>
  )
}

export default LoadingSpinner

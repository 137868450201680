import {css} from '@emotion/core'
import * as theme from './theme'

export const GlobalStyle = css`
  ::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    height: 100%;
    background-color: ${theme.LighterGrayColor};
    font-family: sans-serif;
  }
  html::-webkit-scrollbar {
    display: none;
  }
  .anticon {
    display: inline-flex !important;
  }
  #root {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .underline {
    text-decoration: underline;
  }
  textarea {
    resize: none;
  }
  small.label {
    text-decoration: underline;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .switch-label {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${theme.DarkGray};
    text-transform: uppercase;
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed ${theme.PrimaryColor} !important;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed ${theme.PrimaryColor} !important;
  }
  blockquote {
    font-style: italic;
  }
  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 0px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  .custom-input .ant-input-group-addon {
    padding-top: 3px !important;
  }
  .ad-tags .ant-table-row td {
    border: 0 !important;
  }
  .clickable {
    cursor: pointer;
  }
  .ant-modal-title {
    font-weight: 700 !important;
  }
  .caseRowCaseStyle {
    height: 70px;
  }
  .ant-form-horizontal .ant-form-item-label {
    padding-top: 6px;
    line-height: 1rem !important;
    white-space: normal !important;
  }
  .ant-form-vertical {
    width: 100%;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${theme.LighterGrayColor}!important;
  }
  .caseRowSectionStyle {
    height: 70px;
    background: ${theme.ClassicComponentBackground};
  }
  .selectedRow {
    background: #e6f7ff;
  }
  .ghostLink:hover {
    color: ${theme.PrimaryColor};
  }
  .customLink {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    height: auto !important;
  }
  .sideSectionArea {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 20px;
  }
  .sideSection {
    border-bottom: 1px solid ${theme.BorderLineColor};
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${theme.GrayColor};
    letter-spacing: 1px;
  }
  .actionButtons {
    Button {
      margin-right: 10px;
    }
    Button:last-of-type {
      margin-right: 0;
    }
  }
  .editForm {
    > div {
      margin-bottom: 15px;
    }
  }
  .ant-tag:last-of-type {
    margin-right: 0 !important;
  }
  .ant-table-placeholder {
    border-radius: 0 !important;
  }
  .ant-tabs-nav-scroll .ant-tabs-tab:first-of-type {
    margin-left: 20px !important;
  }
  .ant-table-row:hover .showOnHover {
    display: inline-block;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 700;
  }
  .ant-drawer-content-wrapper {
  }
  .ant-drawer-content,
  .ant-drawer-wrapper-body {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .ant-tabs-bar {
    margin-bottom: 0 !important;
  }
  .ant-modal-body {
    padding-top: 10px;
  }

  .ant-modal-confirm .ant-modal-confirm-btns {
    display: flex;
    justify-content: right;
  }

  .ant-divider-vertical {
    background-color: ${theme.BorderLineColor} !important;
  }

  .ant-form-item-label {
    font-weight: 700;
    color: #8c8c8c;
  }

  .ant-form-item {
    width: 100%;
  }

  .reportResults .ant-table-tbody tr:last-child td,
  .reportResults .ant-table-tbody tr:last-child:hover td {
    background: #fafafa;
    font-weight: bold;
  }
  .ant-layout-header {
    padding: 0 !important;
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }

  .ant-form-item:last-of-type {
    // margin-bottom: 0 !important;
  }

  // custom

  .ComponentShadow {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
  }

  .ComponentSimpleShadow {
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1),
      0 10px 30px #f3ece8;
  }

  .FormShadow {
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.1),
      0 1px 10px #f3ece8;
  }

  .PageCentered {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .centered {
    text-align: center;
  }

  .ant-form-item-explain {
    margin-top: 5px !important;
    font-size: 0.8rem;

    svg {
      width: 0.7rem !important;
      height: 0.7rem !important;
    }
  }

  .TextCapitalize {
    text-transform: capitalize;
  }

  .TextUppercase {
    text-transform: uppercase;
  }

  .TextSmall {
    font-size: 0.8rem;
  }

  .ant-form-item-control .ant-typography {
    margin-bottom: 0 !important;
  }

  .TextNoActive {
    color: ${theme.GrayColor};
  }

  .Clickable {
    cursor: pointer;
  }

  .ant-radio-button-wrapper {
    width: 33%;
    display: flex !important;
    flex: 1 !important;
    padding: 0 !important;

    > span:last-child {
      padding: 0 !important;
      display: flex !important;
      flex: 1 !important;
      align-self: center;
      justify-content: center;

      img {
        width: 70%;
      }
    }
  }

  .ant-radio-group {
    width: 100%;
  }

  .MenuItem {
    background-color: ${theme.LighterGrayColor};
    color: ${theme.BlackColor};
    padding: 5px 15px;
    border-radius: 20px;
    min-width: 100px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background-color: ${theme.LightGrayColor};
      color: ${theme.BlackColor};
    }
  }

  .MenuItemSelected {
    background-color: ${theme.PrimaryColor};
    color: ${theme.WhiteColor};

    &:hover {
      background-color: ${theme.BlackColor};
      color: ${theme.WhiteColor};
    }
  }

  .BreadcrumbLink {
    font-size: 16px !important;
    color: ${theme.SecondaryColor}!important;
  }

  .PageContent {
    > div:first-of-type {
      .ant-divider {
        margin-top: 0;
      }
    }

    .ant-divider {
      font-weight: 700 !important;
    }
  }

  .SectionSubtitle {
    font-weight: normal;
    color: ${theme.GrayColor};
    font-size: 0.8rem;
    margin-left: 10px;
  }

  .InlineAlert {
    margin-bottom: 15px !important;
  }

  .ButtonLink {
    padding: 0 !important;
  }

  .FileSize {
    margin-left: 10px;
  }

  .PaperSheetNarrow {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100vw;
    width: 100%;
    background: transparent;
    height: 100%;
    align-items: center;

    .PaperContentNarrow {
      margin-top: 25px;
      width: 100%;
      max-width: 1220px;
    }

    .PaperContent {
      margin-top: 50px;
      width: 100%;
      max-width: 1220px;
    }

    .PaperContentWide {
      padding: 50px;
      width: 100%;
      max-width: 100%;
    }

    .PaperContentFull {
      position: relative;
      display: flex;
      flex: 1;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;
    }
  }

  .PaperSheet {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100vw;
    width: 100%;
    background: transparent;
    height: 100%;
    margin-top: 60px;
    align-items: center;

    .PaperContent {
      margin-top: 50px;
      width: 100%;
      max-width: 1220px;
    }

    .PaperContentWide {
      padding: 50px;
      width: 100%;
      max-width: 100%;
    }

    .PaperContentFull {
      position: relative;
      display: flex;
      flex: 1;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;
    }
  }

  .PaperZero {
    margin-top: 0;
  }

  .grip {
    height: 25px !important;
  }

  .react-mde-tabbed-layout,
  .react-mde {
    border-radius: ${theme.BorderRadiusBase}!important;
  }

  .mde-header {
    border-top-left-radius: ${theme.BorderRadiusBase}!important;
    border-top-right-radius: ${theme.BorderRadiusBase}!important;
  }

  .grip {
    border-bottom-left-radius: ${theme.BorderRadiusBase}!important;
    border-bottom-right-radius: ${theme.BorderRadiusBase}!important;
  }

  .site-page-header {
    width: 100% !important;
    padding: 5px 15px !important;
    border-bottom: 1px solid ${theme.BorderLineColor};
    background-color: ${theme.ClassicComponentBackground}!important;
  }

  .site-page-header-light {
    width: 100% !important;
    padding: 5px 15px !important;
    border-bottom: 1px solid ${theme.BorderLineColor};
    background-color: ${theme.WhiteColor}!important;
  }

  .maxed {
    width: 100% !important;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-bold {
    font-weight: 700;
  }

  .AlignRight {
    text-align: right;
  }

  .TextNotActive {
    color: ${theme.GrayColor}!important;
  }

  .ant-input-search-button {
    border-color: ${theme.WhiteColor} !important;
  }

  .Card {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    border: 5px solid transparent;
    border-radius: ${theme.BorderRadiusBase};
    align-items: stretch;
    cursor: pointer;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    background: #f7f7f7;

    &:hover {
      transform: translate(0px, -5px);
    }

    .CardImage img {
      width: 100%;
      border-top-left-radius: ${theme.BorderRadiusBase};
      border-top-right-radius: ${theme.BorderRadiusBase};
    }

    .CardMeta {
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 15px;
      .name {
        color: ${theme.GrayColor};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 35px;
      }
      .description {
        font-weight: bold;
        font-size: 0.8rem;
        color: ${theme.GrayColor};
      }
    }

    .CardFooter {
      background-color: ${theme.WhiteColor};
      padding: 5px 10px;
      border-radius: ${theme.BorderRadiusBase};
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
      > * {
        width: 100%;
      }
    }

    .CardActions {
      margin-top: 5px;
      font-size: 0.8rem;
      color: ${theme.GrayColor};
    }
  }

  .CardSelected {
    border: 2px solid ${theme.BrandColor};
  }

  .emulated-flex-gap {
    --gap: 15px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
  }

  .emulated-flex-gap > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav-list {
    margin-left: 15px;
  }

  .ant-tree-directory {
    border-radius: 0 !important;
  }

  .monaco-editor {
    display: table;
    height: 100% !important;
    min-height: 100% !important;
  }

  .ButtonTextOnDark {
    color: ${theme.WhiteColor}!important;
    background-color: ${theme.LightBlackColor} !important;
    &:hover {
      background-color: ${theme.ButtonOnDarkColor}!important;
    }

    &.Active {
      background-color: ${theme.ButtonOnDarkColor}!important;
    }
  }

  .ButtonTextOnDarkCircle {
    background-color: ${theme.ButtonOnDarkColor}!important;
  }

  .Gradient3 {
    background-image: repeating-linear-gradient(
        90deg,
        hsla(196, 0%, 79%, 0.06) 0px,
        hsla(196, 0%, 79%, 0.06) 1px,
        transparent 1px,
        transparent 96px
      ),
      repeating-linear-gradient(
        0deg,
        hsla(196, 0%, 79%, 0.06) 0px,
        hsla(196, 0%, 79%, 0.06) 1px,
        transparent 1px,
        transparent 96px
      ),
      repeating-linear-gradient(
        0deg,
        hsla(196, 0%, 79%, 0.09) 0px,
        hsla(196, 0%, 79%, 0.09) 1px,
        transparent 1px,
        transparent 12px
      ),
      repeating-linear-gradient(
        90deg,
        hsla(196, 0%, 79%, 0.09) 0px,
        hsla(196, 0%, 79%, 0.09) 1px,
        transparent 1px,
        transparent 12px
      ),
      linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  }

  .Gradient4 {
    background-image: repeating-linear-gradient(
        0deg,
        rgba(219, 219, 219, 0.2) 0px,
        rgba(219, 219, 219, 0.2) 1px,
        transparent 1px,
        transparent 21px
      ),
      repeating-linear-gradient(
        90deg,
        rgba(219, 219, 219, 0.2) 0px,
        rgba(219, 219, 219, 0.2) 1px,
        transparent 1px,
        transparent 21px
      ),
      linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  }

  .pattern-checks-sm {
    background-image: repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      ),
      repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      );
    background-position: 0 0, 10px 10px;
    background-size: calc(2 * 10px) calc(2 * 10px);
  }
  .pattern-checks-md {
    background-image: repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      ),
      repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      );
    background-position: 0 0, 25px 25px;
    background-size: calc(2 * 25px) calc(2 * 25px);
  }
  .pattern-checks-lg {
    background-image: repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      ),
      repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      );
    background-position: 0 0, 50px 50px;
    background-size: calc(2 * 50px) calc(2 * 50px);
  }
  .pattern-checks-xl {
    background-image: repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      ),
      repeating-linear-gradient(
        45deg,
        currentColor 25%,
        transparent 25%,
        transparent 75%,
        currentColor 75%,
        currentColor
      );
    background-position: 0 0, 100px 100px;
    background-size: calc(2 * 100px) calc(2 * 100px);
  }
  .pattern-grid-sm {
    background-image: linear-gradient(currentColor 1px, transparent 1px),
      linear-gradient(to right, currentColor 1px, transparent 1px);
    background-size: 10px 10px;
  }
  .pattern-grid-md {
    background-image: linear-gradient(currentColor 1px, transparent 1px),
      linear-gradient(to right, currentColor 1px, transparent 1px);
    background-size: 25px 25px;
  }
  .pattern-grid-lg {
    background-image: linear-gradient(currentColor 1px, transparent 1px),
      linear-gradient(to right, currentColor 1px, transparent 1px);
    background-size: 50px 50px;
  }
  .pattern-grid-xl {
    background-image: linear-gradient(currentColor 1px, transparent 1px),
      linear-gradient(to right, currentColor 1px, transparent 1px);
    background-size: 100px 100px;
  }
  .pattern-dots-sm {
    background-image: radial-gradient(currentColor 0.5px, transparent 0.5px);
    background-size: calc(10 * 0.5px) calc(10 * 0.5px);
  }
  .pattern-dots-md {
    background-image: radial-gradient(currentColor 1px, transparent 1px);
    background-size: calc(10 * 1px) calc(10 * 1px);
  }
  .pattern-dots-lg {
    background-image: radial-gradient(currentColor 1.5px, transparent 1.5px);
    background-size: calc(10 * 1.5px) calc(10 * 1.5px);
  }
  .pattern-dots-xl {
    background-image: radial-gradient(currentColor 2px, transparent 2px);
    background-size: calc(10 * 2px) calc(10 * 2px);
  }
  .pattern-cross-dots-sm {
    background-image: radial-gradient(currentColor 0.5px, transparent 0.5px),
      radial-gradient(currentColor 0.5px, transparent 0.5px);
    background-size: calc(20 * 0.5px) calc(20 * 0.5px);
    background-position: 0 0, calc(10 * 0.5px) calc(10 * 0.5px);
  }
  .pattern-cross-dots-md {
    background-image: radial-gradient(currentColor 1px, transparent 1px),
      radial-gradient(currentColor 1px, transparent 1px);
    background-size: calc(20 * 1px) calc(20 * 1px);
    background-position: 0 0, calc(10 * 1px) calc(10 * 1px);
  }
  .pattern-cross-dots-lg {
    background-image: radial-gradient(currentColor 1.5px, transparent 1.5px),
      radial-gradient(currentColor 1.5px, transparent 1.5px);
    background-size: calc(20 * 1.5px) calc(20 * 1.5px);
    background-position: 0 0, calc(10 * 1.5px) calc(10 * 1.5px);
  }
  .pattern-cross-dots-xl {
    background-image: radial-gradient(currentColor 2px, transparent 2px),
      radial-gradient(currentColor 2px, transparent 2px);
    background-size: calc(20 * 2px) calc(20 * 2px);
    background-position: 0 0, calc(10 * 2px) calc(10 * 2px);
  }
  .pattern-vertical-lines-sm {
    background-image: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 10px 10px;
  }
  .pattern-horizontal-lines-sm {
    background-image: repeating-linear-gradient(
      0deg,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 10px 10px;
  }
  .pattern-diagonal-lines-sm {
    background-image: repeating-linear-gradient(
      45deg,
      currentColor 0,
      currentColor 1px,
      transparent 0,
      transparent 50%
    );
    background-size: 10px 10px;
  }
  .pattern-vertical-lines-md {
    background-image: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 25px 25px;
  }
  .pattern-horizontal-lines-md {
    background-image: repeating-linear-gradient(
      0deg,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 25px 25px;
  }
  .pattern-diagonal-lines-md {
    background-image: repeating-linear-gradient(
      45deg,
      currentColor 0,
      currentColor 1px,
      transparent 0,
      transparent 50%
    );
    background-size: 25px 25px;
  }
  .pattern-vertical-lines-lg {
    background-image: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 50px 50px;
  }
  .pattern-horizontal-lines-lg {
    background-image: repeating-linear-gradient(
      0deg,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 50px 50px;
  }
  .pattern-diagonal-lines-lg {
    background-image: repeating-linear-gradient(
      45deg,
      currentColor 0,
      currentColor 1px,
      transparent 0,
      transparent 50%
    );
    background-size: 50px 50px;
  }
  .pattern-vertical-lines-xl {
    background-image: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 100px 100px;
  }
  .pattern-horizontal-lines-xl {
    background-image: repeating-linear-gradient(
      0deg,
      currentColor,
      currentColor 1px,
      transparent 1px,
      transparent
    );
    background-size: 100px 100px;
  }
  .pattern-diagonal-lines-xl {
    background-image: repeating-linear-gradient(
      45deg,
      currentColor 0,
      currentColor 1px,
      transparent 0,
      transparent 50%
    );
    background-size: 100px 100px;
  }
  .pattern-vertical-stripes-sm {
    background-image: linear-gradient(90deg, transparent 50%, currentColor 50%);
    background-size: 10px 10px;
  }
  .pattern-horizontal-stripes-sm {
    background-image: linear-gradient(0deg, transparent 50%, currentColor 50%);
    background-size: 10px 10px;
  }
  .pattern-diagonal-stripes-sm {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      currentColor 10px,
      currentColor calc(2 * 10px)
    );
  }
  .pattern-vertical-stripes-md {
    background-image: linear-gradient(90deg, transparent 50%, currentColor 50%);
    background-size: 25px 25px;
  }
  .pattern-horizontal-stripes-md {
    background-image: linear-gradient(0deg, transparent 50%, currentColor 50%);
    background-size: 25px 25px;
  }
  .pattern-diagonal-stripes-md {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 25px,
      currentColor 25px,
      currentColor calc(2 * 25px)
    );
  }
  .pattern-vertical-stripes-lg {
    background-image: linear-gradient(90deg, transparent 50%, currentColor 50%);
    background-size: 50px 50px;
  }
  .pattern-horizontal-stripes-lg {
    background-image: linear-gradient(0deg, transparent 50%, currentColor 50%);
    background-size: 50px 50px;
  }
  .pattern-diagonal-stripes-lg {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 50px,
      currentColor 50px,
      currentColor calc(2 * 50px)
    );
  }
  .pattern-vertical-stripes-xl {
    background-image: linear-gradient(90deg, transparent 50%, currentColor 50%);
    background-size: 100px 100px;
  }
  .pattern-horizontal-stripes-xl {
    background-image: linear-gradient(0deg, transparent 50%, currentColor 50%);
    background-size: 100px 100px;
  }
  .pattern-diagonal-stripes-xl {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 100px,
      currentColor 100px,
      currentColor calc(2 * 100px)
    );
  }
  .pattern-zigzag-sm {
    background: linear-gradient(135deg, currentColor 25%, transparent 25%) -10px
        0,
      linear-gradient(225deg, currentColor 25%, transparent 25%) -10px 0,
      linear-gradient(315deg, currentColor 25%, transparent 25%),
      linear-gradient(45deg, currentColor 25%, transparent 25%);
    background-size: calc(2 * 10px) calc(2 * 10px);
  }
  .pattern-zigzag-md {
    background: linear-gradient(135deg, currentColor 25%, transparent 25%) -25px
        0,
      linear-gradient(225deg, currentColor 25%, transparent 25%) -25px 0,
      linear-gradient(315deg, currentColor 25%, transparent 25%),
      linear-gradient(45deg, currentColor 25%, transparent 25%);
    background-size: calc(2 * 25px) calc(2 * 25px);
  }
  .pattern-zigzag-lg {
    background: linear-gradient(135deg, currentColor 25%, transparent 25%) -50px
        0,
      linear-gradient(225deg, currentColor 25%, transparent 25%) -50px 0,
      linear-gradient(315deg, currentColor 25%, transparent 25%),
      linear-gradient(45deg, currentColor 25%, transparent 25%);
    background-size: calc(2 * 50px) calc(2 * 50px);
  }
  .pattern-zigzag-xl {
    background: linear-gradient(135deg, currentColor 25%, transparent 25%) -100px
        0,
      linear-gradient(225deg, currentColor 25%, transparent 25%) -100px 0,
      linear-gradient(315deg, currentColor 25%, transparent 25%),
      linear-gradient(45deg, currentColor 25%, transparent 25%);
    background-size: calc(2 * 100px) calc(2 * 100px);
  }
  .pattern-triangles-sm {
    background-image: linear-gradient(45deg, currentColor 50%, transparent 50%);
    background-size: 10px 10px;
  }
  .pattern-triangles-md {
    background-image: linear-gradient(45deg, currentColor 50%, transparent 50%);
    background-size: 25px 25px;
  }
  .pattern-triangles-lg {
    background-image: linear-gradient(45deg, currentColor 50%, transparent 50%);
    background-size: 50px 50px;
  }
  .pattern-triangles-xl {
    background-image: linear-gradient(45deg, currentColor 50%, transparent 50%);
    background-size: 100px 100px;
  }
  .text-pattern {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .pattern-w-sm {
    width: 10px;
  }
  .pattern-h-sm {
    height: 10px;
  }
  .pattern-w-md {
    width: 25px;
  }
  .pattern-h-md {
    height: 25px;
  }
  .pattern-w-lg {
    width: 50px;
  }
  .pattern-h-lg {
    height: 50px;
  }
  .pattern-w-xl {
    width: 100px;
  }
  .pattern-h-xl {
    height: 100px;
  }

  .pattern-grid-color {
    color: ${theme.LightGrayColor};
  }

  .animatedSize {
    transition: all 0.5s linear;
  }

  .InspectorPanel {
    .ant-form-item-label {
      font-size: 0.8rem !important;
      line-height: 1rem !important;
      height: 1.6rem !important;
    }

    label {
      color: ${theme.DarkGray} !important;
      margin-bottom: 5px !important;
    }
  }

  .ant-tree-treenode {
    padding: 0 !important;
    border: 0 !important;
  }

  .ant-tree-node-content-wrapper {
    padding: 5px 0 8px 0 !important;
    line-height: auto !important;
  }

  .PropertyLabel {
    color: ${theme.DarkGray} !important;
  }

  .ant-tree-switcher {
    padding-top: 5px;
  }

  .ant-tree-switcher-icon {
  }

  .ant-tabs-tabpane {
    display: flex;
    flex: 1;
    height: calc(100vh - 174px);
  }

  .playground-editor {
    background-color: ${theme.WhiteColor};
    position: relative;
    display: flex;
    flex: 1;
    width: 1px;
    display: flex;
    flex: 1;
    overflow: hidden;

    .react-codemirror2 {
      width: 100%;
      display: flex;
      flex: 1;
    }

    .CodeMirror-scroll {
      width: 100%;
      display: flex;
      flex: 1;
    }

    .CodeMirror {
      display: flex;
      border-radius: 5px;
      flex: 1;
      min-height: 100%;
      min-width: 100%;
    }

    pre {
      font-size: 14px;
      line-height: 1.5;
      color: #333;
      margin: 0 !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      background: none !important;
    }
  }

  .ModalFooter {
    padding: 15px 20px;
    text-align: right;
    background-color: ${theme.ClassicComponentBackground};
    border-top: 1px solid ${theme.LightGrayColor};
    border-bottom-left-radius: ${theme.BorderRadiusBase};
    border-bottom-right-radius: ${theme.BorderRadiusBase};
    display: flex;
    flex: 1;

    > div {
      display: flex;
      flex: 1;
      align-items: center;
    }

    > div.on-right {
      justify-content: flex-end;
    }
  }

  .CampaignsTable {
    .ant-table-pagination.ant-pagination {
      margin: 16px 30px;
    }
  }

  .highlighted {
    color: ${theme.PrimaryColor};
  }

  .largeIcon {
    font-size: 3rem;
    margin-bottom: 15px;
    color: ${theme.GreenColor};
  }

  .largeIconError {
    color: ${theme.RedColor};
  }

  .colorGreen {
    color: ${theme.GreenColor};
  }

  .colorBlue {
    color: ${theme.BlueColor};
  }

  .colorRed {
    color: ${theme.RedColor};
  }

  .inlineFlex {
    display: flex;
    align-items: center;
  }

  .inlineIcon {
    margin-right: 10px;
  }

  .dragFullHeight {
    height: 250px !important;
  }

  .wrap-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-upload-text {
    margin-bottom: 15px !important;
  }

  .list-components-layout {
    padding-left: 24px !important;
  }

  .ant-tree-node-selected .anticon,
  .ant-tree-node-selected .ant-tree-title span {
    color: ${theme.WhiteColor} !important;
  }

  .pr-5 {
    padding-right: 5px;
  }

  .px-5 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .ant-btn {
    // align-items: center;
    // font-size: 0.9rem;
  }

  .ant-btn-sm {
    // font-size: 0.8rem;
  }

  .ant-popover-buttons {
    .ant-btn-sm {
      font-size: 0.8rem;
    }
  }

  .global-search {
    .ant-input-affix-wrapper {
      border-top-left-radius: 25px !important;
      border-bottom-left-radius: 25px !important;
    }

    .ant-input-group-addon,
    .ant-input-search-button {
      border-top-right-radius: 25px !important;
      border-bottom-right-radius: 25px !important;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 10px !important;
    padding-right: 31px !important;
  }

  .ant-drawer-header {
    background: white !important;
    display: none !important;
  }

  .preview-drawer {
    .ant-drawer-content-wrapper {
      height: auto !important;
      background: transparent !important;
      box-shadow: none !important;
      opacity: 0.8 !important;
    }

    .ant-drawer-content {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      max-width: 1000px !important;
      min-width: max-content !important;
      margin: 0 auto 20px !important;

      .ant-drawer-body {
        background: black !important;
        padding: 12px !important;
      }
    }
  }

  .CoverImage {
    .overlay {
      display: none;
    }
  }

  .CoverImage:hover {
    .overlay {
      display: block;
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    border: 1px solid #1890fe !important;
    background: #e6f6ff !important;
    color: #1890fe !important;
  }

  .ant-select input {
    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }

  .ant-menu-item-active,
  .ant-dropdown-menu-item-active {
    color: #000000 !important;
    background: #ffffff !important;

    .ant-menu-item-icon {
      color: #000000 !important;
    }

    .ant-menu-title-content {
      color: #000000 !important;
    }

    &:hover {
      color: #000000 !important;
      background: rgba(24, 144, 255, 0.4) !important;
    }
  }
  
  .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper {
    background-color: transparent !important;
  }
  .ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background-color: #f5f5f5 !important;
  }

  .ant-tabs-tabpane-hidden {
    display: none !important;
  }
  
  .splitViewCloseConfirm {
    .ant-modal-confirm-btns {
      display: none;
    }
  }

  .dropping {
    background: #fefefe;
    transition: all 0.3s;
  }

  //[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  //  --tw-ring-shadow: unset;
  //}
`

import {ApiResponse, User} from '../types/types'
import client from './api-client'
import {TOKEN_LOCAL_STORAGE_KEY} from './global'

function saveToken(token: string) {
  if (!token) {
    return
  }
  if (token) {
    window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token)
  }
  return token
}

function logoutCurrentUser() {
  return Promise.resolve(window.localStorage.clear())
}

function hasLoggedInUser() {
  return Boolean(window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY))
}

async function getCurrentUser() {
  // TODO: Do we need this here? test test
  const token = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)
  if (!token) {
    throw new Error('No token')
  }

  return (await client('auth', {method: 'post'})) as ApiResponse<User>
}

export {getCurrentUser, hasLoggedInUser, logoutCurrentUser, saveToken}

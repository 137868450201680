import {navigate} from '@reach/router'
import {Dictionary, EventObject} from '../types/types'
import {logoutCurrentUser} from './auth-client'

import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from '@ant-design/icons'
const {encode} = require('./sdk')

const envSettings = window as any

type InskinApps = 'studio' | 'showcase'

export const TOKEN_LOCAL_STORAGE_KEY = '__inskin_creator_token__'

const {REACT_APP_OAUTH_RETURN_URL = '', REACT_APP_LOGOUT_URL = ''} = process.env

export const APP_FOLDER: InskinApps =
  (process.env.REACT_APP_FOLDER as InskinApps) || 'studio'

export const LOGOUT_URL = `${REACT_APP_LOGOUT_URL}/logout?redirect=${encodeURIComponent(
  REACT_APP_OAUTH_RETURN_URL,
)}`

export const logout = () => {
  logoutCurrentUser().then(() => {
    window.location.href = LOGOUT_URL
  })
}

export const getNextLogoutUrl = (APPS = '') => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)

  let nextLogoutUrl = ''

  const paramRedirect = params.get('redirect')

  if (paramRedirect) {
    nextLogoutUrl = `${
      paramRedirect.startsWith('http') ? '' : 'http://'
    }${paramRedirect}`
  }

  const re =
    /^([a-z0-9]+)((\.dev|\.staging)?\.(inskinmedia\.com|arcplatform\.net))$/
  const matches = url.hostname.match(re)
  if (matches) {
    const appsParam = params.get('apps') === null ? APPS : params.get('apps')
    const apps = appsParam ? appsParam.split(',') : []

    const app = matches[1]
    const i = apps.indexOf(app)
    if (i > -1) {
      apps.splice(i, 1)
    }

    const queryString = paramRedirect
      ? `&redirect=${encodeURIComponent(paramRedirect)}`
      : ''

    if (apps.length) {
      const domain = matches[2]
      const nextApp = apps.shift()
      nextLogoutUrl = `${
        url.protocol
      }//${nextApp}${domain}/logout?apps=${apps.join(',')}${queryString}`
    }
  }

  return nextLogoutUrl
}

export const isManager = (member: any) => {
  if (!member) {
    return false
  }
  switch (member.role) {
    case 'owner':
      return true
    case 'manager':
      return true
    default:
      return false
  }
}

export const redirect = (accountId: string | null, page = '') =>
  navigate(`/accounts/${accountId}${page ? `/${page}` : ''}`)

export const parseNumber = (name: string) => {
  const lastNumber = name?.match(/\d+$/)
  return lastNumber ? parseInt(lastNumber[0]) : 0
}

export const getPattern = (find: string = '') =>
  new RegExp('\\{\\{(?:\\\\s+)?(' + find + ')(?:\\\\s+)?\\}\\}', 'g')

export const parseText = (templateContent: string) => ({
  with: (options: Dictionary<any>) => {
    return Object.keys(options).reduce((result: string, key: string) => {
      return result.replace(getPattern(key), options[key])
    }, templateContent)
  },
})

export function slugify(str: string = '') {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  // remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, '')
    // collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // collapse dashes
    .replace(/-+/g, '-')

  return str
}

// export function encodeMassTag(
//   provider: string,
//   product: string,
//   endpoint: string,
//   params: any,
// ) {
//   return encode(provider, product, endpoint, params)
// }

export function encodeMassTag(provider: string, product: string, params: any) {
  return encode(provider, product, params)
}

export const percent = (value = 0, max = 0) => {
  if (!value) return 0
  return Math.floor((value / max) * 100)
}

export const noSpecialChars = (_: any, value: any) => {
  const test = /[^a-zA-Z0-9\-\s/]/.test(value)
  if (test) {
    return Promise.reject('Special characters are not supported')
  } else {
    return Promise.resolve()
  }
}

export const max125chars = (_: any, value: any) => {
  if (value?.length > 125) {
    return Promise.reject('Max 125 characters allowed')
  } else {
    return Promise.resolve()
  }
}

export const validatorUrl = (_: any, value: any) => {
  if (!value) {
    return Promise.resolve()
  }

  const testA =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      value,
    )

  const testB =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      value,
    )

  if (testA || testB) {
    return Promise.resolve()
  } else {
    return Promise.reject('The URL is not valid')
  }
}

export const convertProductAndDeviceToShortcut = (
  product: string,
  device: string,
) => {
  let result = ''
  let deviceShortcut = ''

  if (device === 'Desktop') {
    deviceShortcut = 'D'
  } else if (device === 'Tablet') {
    deviceShortcut = 'T'
  } else if (device === 'Smartphone') {
    deviceShortcut = 'S'
  }

  switch (product) {
    case 'Pageskin':
    case 'Pageskin Plus':
      result = 'PS' + deviceShortcut
      break
    case 'Superwide':
      result = 'PSSW'
      break
    case 'Pageskin Edge':
      result = 'PET'
      break
    case 'Pagescroll':
      result = 'PSCR'
      break
    case 'Baseline':
      result = 'BL'
      break
    case 'Pagelead':
      result = 'PL'
      break
  }

  return result
}

export function convertJsonTrackersToArray(
  events: Dictionary<EventObject>,
): EventObject[] {
  const trackersArray: EventObject[] = []
  Object.keys(events).forEach(key => {
    trackersArray.push(events[key])
  })

  return trackersArray
}

export const HOTJAR_ID = envSettings.HOTJAR_ID || null

export const HOTJAR_SNIPPET_VER = envSettings.HOTJAR_SNIPPET_VER || null

export const formats = [
  {
    key: 'pageskin',
    name: 'Pageskin',
    devices: ['desktop', 'tablet', 'smartphone'],
  },
  {key: 'superwide', name: 'Superwide', devices: ['desktop']},
  {key: 'pageskinEdge', name: 'Pageskin Edge', devices: ['tablet']},
  {key: 'pagescroll', name: 'Pagescroll', devices: ['smartphone']},
  {key: 'baseline', name: 'Baseline', devices: ['smartphone']},
  {key: 'pagelead', name: 'Pagelead', devices: ['smartphone']},
]
export const formatsMap = formats.reduce((acc: any, f: any) => {
  acc[f.key] = f

  return acc
}, {})

export const devices = [
  {key: 'desktop', name: 'Desktop', icon: DesktopOutlined},
  {key: 'tablet', name: 'Tablet', icon: TabletOutlined},
  {key: 'smartphone', name: 'Smartphone', icon: MobileOutlined},
]
export const devicesMap = devices.reduce((acc: any, f) => {
  acc[f.key] = f

  return acc
}, {})

import {css} from '@emotion/core'

export const LoadingSpinnerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100vw;
  height: 100vh;
`

const PrimaryColor = '#1890ff'
const PrimaryBorderColor = '#bcad75'
const DefaultColor = '#fa541c'
const DefaultBorderColor = '#d4380d'
const BorderLineColor = '#d9d9d9'
const ClassicComponentBackground = '#f0f0f0'
const PrimaryHighlightColor = '#e6f7ff'
const SecondaryColor = '#521d49'

const Transparent = 'transparent'
const WhiteColor = '#ffffff'
const LighterGrayColor = '#f7f7f7';
const LightGrayColor = '#e5e5e5';
const MiddleGrayColor = '#bdbdbd';
const GrayColor = '#8c8c8c'
const DarkGray = '#696969'
const LightBlackColor = '#252525'
const BlackColor = '#1f1f1f'
const BackgroundColor = '#f4f4f4';
const RedColor = '#f5222d'
const GreenColor = '#52c41a'
const LimeColor = '#389e0d'
const OrangeColor = '#fa8c16'
const LightYellowColor = '#ffffb8'
const LighterYellowColor = '#fffb8f'
const YellowColor = '#fadb14'
const LighterBlueColor = '#e6f7ff'
const BlueColor = '#1B6AC9'
const BrandColor = '#521d49'

const BorderRadiusBase = '5px'
const BorderWidthBase = '2px'

const PlaceholderIconColor = 'rgba(0,0,0,.25)'
const ButtonOnDarkColor = 'rgba(255,255,255,.25)'

module.exports = {
  PrimaryColor,
  PrimaryBorderColor,
  DefaultColor,
  DefaultBorderColor,
  BorderLineColor,
  ClassicComponentBackground,
  PrimaryHighlightColor,
  SecondaryColor,

  BackgroundColor,
  WhiteColor,
  RedColor,
  GreenColor,
  Transparent,
  GrayColor,
  BlackColor,
  LightBlackColor,
  OrangeColor,
  YellowColor,
  LighterYellowColor,
  LightYellowColor,
  LighterGrayColor,
  LightGrayColor,
  MiddleGrayColor,
  LimeColor,
  BlueColor,
  LighterBlueColor,
  BrandColor,
  DarkGray,

  BorderRadiusBase,
  BorderWidthBase,

  PlaceholderIconColor,
  ButtonOnDarkColor
}